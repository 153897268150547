import {
  SET_CARD_ERROR_OCCURED,
  LOADING_OTP,
  OTP_CONFIRMATION,
  OTP_CREATION,
  OTP_ERROR,
  OTP_CLEAR,
} from 'constants/ActionTypes';
import { purchase } from 'js-api-client';
import { camelizeKeys } from 'humps';
import cybersourceErrorCodes from 'utils/Cybersource';
import { getSessionId } from 'utils/session';
import { getSearchParams, generateSearchRedirectUrl } from 'utils/searchUrlHelpers';
import { getDistinctId } from 'user-analytics';
import store from '@/store';
import { setError } from '.';
import {
  getPurchase,
  updatePurchase,
  receivePurchase,
  receivePayment,
  requestPayment,
  expirePurchase,
} from './purchase';
import availablePaymentEngines from '../constants/PaymentEngine';
import storeFactory from '../../payments/core/factories/store/storeFactory';
import transferFactory from '../../payments/core/factories/transfer';
import cardFactory from '../../payments/core/factories/card';
import { ssoError } from './sso';
import errorMonitoring from '../errorMonitoring';

/**
 * Constructs the payment payload with necessary billing and payment information.
 *
 * @param {string} type - The type of payment, defaults to 'credit_card'.
 * @param {string} engine - The payment engine to be used.
 * @param {Object} cardValues - The card values and billing address information.
 * @returns {Object} The payment payload object.
 */
function getPaymentPayload(type = 'credit_card', engine, cardValues = {}) {
  return {
    billing_address: cardValues.billingAddress,
    payment_type: type,
    payment_engine: type === 'free_pay' ? undefined : engine,
    tracker_id: getDistinctId(),
    device_session_id: getSessionId(),
    monthly_selected_plan: cardValues.monthlySelectedPlan,
  };
}

/**
 * Action creator for setting the card error occurred state.
 *
 * @returns {Object} An action object containing the type SET_CARD_ERROR_OCCURED.
 */
function setCardErrorOccured() {
  return {
    type: SET_CARD_ERROR_OCCURED,
  };
}

/**
 * Action creator for setting the loading state and visibility of the OTP modal.
 *
 * @param {Object} payload - The payload containing the loading state and OTP modal visibility.
 * @param {boolean} payload.loading - The loading state to be set.
 * @param {boolean} payload.showOTPModal - The visibility state of the OTP modal.
 * @returns {Object} An action object containing the type LOADING_OTP and the payload.
 */
export function loadingOTP({ loading, showOTPModal }) {
  return { type: LOADING_OTP, payload: { loading, showOTPModal } };
}

/**
 * Action creator for initiating the OTP creation process.
 *
 * @param {Object} payload - The payload containing the OTP modal visibility state.
 * @param {boolean} payload.showOTPModal - The visibility state of the OTP modal.
 * @returns {Object} An action object containing the type OTP_CREATION and the payload.
 */
export function otpCreation({ showOTPModal }) {
  return { type: OTP_CREATION, payload: { loading: false, showOTPModal } };
}

/**
 * Action creator for confirming the OTP.
 *
 * @param {Object} payload - The payload containing the OTP confirmation state and OTP modal visibility.
 * @param {boolean} payload.otpConfirmed - The state indicating if the OTP was confirmed.
 * @param {boolean} payload.showOTPModal - The visibility state of the OTP modal.
 * @returns {Object} An action object containing the type OTP_CONFIRMATION and the payload.
 */
export function otpConfirmation({ otpConfirmed, showOTPModal }) {
  return { type: OTP_CONFIRMATION, payload: { otpConfirmed, showOTPModal } };
}

/**
 * Action creator for setting the OTP error state.
 *
 * @param {Object} payload - The payload containing the OTP error message and error code.
 * @param {string} payload.otpError - The error message for the OTP error.
 * @param {number} payload.otpErrorCode - The error code for the OTP error.
 * @returns {Object} An action object containing the type OTP_ERROR and the payload.
 */
export function otpError({ otpError, otpErrorCode }) {
  return { type: OTP_ERROR, payload: { otpError, otpErrorCode } };
}

/**
 * Retrieves the endpoint for OTP based on the wallet type from the state.
 *
 * @returns {string} The endpoint for the OTP service.
 */
function getOTPEndpoint() {
  const { walletType } = store.getState().purchase.toJS();
  return walletType;
}

/**
 * Clean the OTP state
 */
export function clearOTP() {
  return { type: OTP_CLEAR };
}

/**
 * Initiates the OTP generation process.
 *
 * @param {number} pointsUsed - The number of points to be used for the OTP generation.
 * @returns {Function} A thunk action that dispatches actions based on the OTP generation process.
 */
export function generateOTP(pointsUsed) {
  return (dispatch) => {
    const {
      whitelabelConfig: { features },
    } = store.getState();
    dispatch(loadingOTP({ loading: true, showOTPModal: false }));
    const payload = { max_redemption_points: pointsUsed };
    purchase
      .generateOTP(getOTPEndpoint(), payload)
      .then(() => {
        dispatch(otpCreation({ showOTPModal: true }));
      })
      .catch((error) => {
        const { code } = error;
        dispatch(loadingOTP({ loading: false, showOTPModal: false }));
        if (code === 401 && features.USE_SSO) {
          dispatch(ssoError(error.code));
        } else {
          dispatch(setError(code, 'otp_creation_error', 'error', false));
        }
      });
  };
}

/**
 * Confirms the OTP entered by the user.
 *
 * @param {string} otp - The one-time password entered by the user.
 * @returns {Function} A thunk action that dispatches actions based on the OTP confirmation process.
 */
export function confirmOTP(otp) {
  return (dispatch) => {
    const {
      whitelabelConfig: { features },
    } = store.getState();
    dispatch(loadingOTP({ loading: true, showOTPModal: true }));
    purchase
      .validateOTP(getOTPEndpoint(), otp)
      .then(() => {
        dispatch(otpConfirmation({ otpConfirmed: true, showOTPModal: false }));
      })
      .catch((error) => {
        const { code } = error;
        dispatch(otpConfirmation({ otpConfirmed: false, showOTPModal: true }));
        if (code === 401 && features.USE_SSO) {
          dispatch(ssoError(code));
        } else {
          dispatch(otpError({ otpError: 'otp_confirmation_error', otpErroCode: code }));
        }
      });
  };
}

/**
 * Handles payment failures by dispatching appropriate error actions and logging the error.
 *
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Object} error - The error object containing details about the payment failure.
 * @param {boolean} [storePayment=false] - Optional flag to indicate if the payment was a store payment.
 */
function paymentFailureHandler(dispatch, error, storePayment = false) {
  const state = store.getState();
  const { features } = state.whitelabelConfig;
  dispatch(updatePurchase(false));
  dispatch(receivePayment());

  if (error.humanErrorReason) {
    dispatch(setError(301, 'null', 'warning', false, error.humanErrorReason));
    dispatch(setCardErrorOccured());
  } else if (error.engine === 'cybersource' && error.reason) {
    dispatch(
      setError(
        301,
        cybersourceErrorCodes[error.reason] || cybersourceErrorCodes.default,
        'warning',
        false,
      ),
    );
  } else if (error.code === 403) {
    dispatch(setError(301, 'finished_time_for_purchase', 'warning', false));
    dispatch(expirePurchase());
  } else if (error.code === 401 && features.USE_SSO) {
    dispatch(ssoError(error.code));
  } else if (storePayment) {
    dispatch(setError(301, 'choose_other_seats', 'warning', false));
  } else if ((error.data && error.data.category === 'request') || error.message_to_purchaser) {
    dispatch(setError(300, 'check_your_card_details', 'warning', false));
  } else if (error?.details?.code === 'unavailable') {
    const searchParams = getSearchParams();
    const redirectURL = generateSearchRedirectUrl(searchParams);
    dispatch(setError(301, 'schedule_unavailable', 'warning', true, undefined, redirectURL));
    // When the payment fails in multiple scenarios (like Doters response), the error message is in the human_error_reason field
    // No use of camelCase here because the error object comes directly from the API and is not set to state
  } else if (error?.details?.human_error_reason) {
    dispatch(setError(301, 'null', 'warning', false, error.details.human_error_reason));
  } else {
    dispatch(setError(301, 'error_generating_payment', 'warning', false));
    dispatch(setCardErrorOccured());
  }

  errorMonitoring.notify({ error, info: 'Error on paymentFailureHandler' });

}

/**
 * Handles the polling of payment status updates and dispatches actions accordingly.
 *
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {string} purchaseToken - The token associated with the purchase.
 * @param {Object} response - The response object from the payment status request.
 */
function paymentPollingHandler(dispatch, purchaseToken, response) {
  const { status, payload } = camelizeKeys(response);
  const { purchaseState, using3dSecure, humanErrorReason } = payload;

  if ((payload.status === 'pending' && using3dSecure) || purchaseState !== 'attempt') {
    dispatch(receivePayment(payload));
    dispatch(getPurchase(purchaseToken));
  } else if (!['pending', 'completed'].includes(status)) {
    // Pix and Nequi Case
    if (
      purchaseState === 'attempt' &&
      (payload.paymentType === 'transfer' || payload.paymentType === 'app_notify') &&
      payload.status === 'pending'
    ) {
      dispatch(receivePayment(payload));
      dispatch(getPurchase(purchaseToken));
    } else if (status === 'retries_exceeded') {
      dispatch(setError(301, 'error_generating_payment', 'warning'));

    } else if (status === 'aborted' && payload.status !== 'failed') {
      dispatch(receivePayment(payload));
      dispatch(getPurchase(purchaseToken));
    } else {
      // ELSE, is an error of some kind
      paymentFailureHandler(
        dispatch,
        (humanErrorReason && { humanErrorReason }) || new Error('Payment Polling Error'),
      );
    }
  }
}

/**
 * Initiates a payment attempt using Efecty.
 *
 * @returns {Function} A thunk action that dispatches actions based on the Efecty payment process.
 */
export function efectyPaymentAttempt() {
  return (dispatch, getState) => {
    const { purchase: purchaseState } = getState();
    const purchaseToken = purchaseState.get('token');
    const paymentPayload = {
      payment_type: 'store',
      payment_engine: availablePaymentEngines.mercadoPago,
      payment_provider: 'efecty',
      tracker_id: getDistinctId(),
      device_session_id: getSessionId(),
      monthly_selected_plan: 1,
    };
    dispatch(requestPayment());
    return purchase
      .createPayment(purchaseToken, paymentPayload, {
        onReceivePayment: (response) => {
          paymentPollingHandler(dispatch, purchaseToken, response);
        },
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

/**
 * Initiates a payment attempt using BNPL.
 *
 * @returns {Function} A thunk action that dispatches actions based on the BNPL payment process.
 */
export function bnplPaymentAttempt() {
  return (dispatch, getState) => {
    const { purchase: purchaseState } = getState();
    const purchaseToken = purchaseState.get('token');
    const selectedPaymentMethod = purchaseState.get('selectedPaymentMethod');
    const paymentEngine = selectedPaymentMethod?.engine ?? 'kueski_pay';
    const paymentProvider = selectedPaymentMethod?.provider ?? 'kueski';
    const paymentPayload = {
      payment_type: 'bnpl',
      payment_engine: paymentEngine,
      payment_provider: paymentProvider,
      tracker_id: getDistinctId(),
      device_session_id: getSessionId(),
      monthly_selected_plan: 1,
    };

    dispatch(requestPayment());

    return purchase
      .createPayment(purchaseToken, paymentPayload, {
        onReceivePayment: (response) => {
          window.location = response.payload.redirect_to;
        },
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

/**
 * Initiates a payment attempt using Wallet.
 *
 * @returns {Function} A thunk action that dispatches actions based on the Wallet payment process.
 */
export function walletPaymentAttempt() {
  return (dispatch, getState) => {
    const { purchase: purchaseState } = getState();
    const purchaseToken = purchaseState.get('token');
    const selectedPaymentMethod = purchaseState.get('selectedPaymentMethod');
    const paymentEngine = selectedPaymentMethod?.engine ?? 'mercadopago';
    const paymentProvider = selectedPaymentMethod?.provider ?? 'mercadopago';
    const paymentPayload = {
      payment_type: 'wallet',
      payment_engine: paymentEngine,
      payment_provider: paymentProvider,
      tracker_id: getDistinctId(),
      device_session_id: getSessionId(),
      monthly_selected_plan: 1,
    };

    dispatch(requestPayment());

    return purchase
      .createPayment(purchaseToken, paymentPayload, {
        onReceivePayment: (response) => {
          window.location = response.payload.redirect_to;
        },
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

/**
 * Initiates a credit card payment attempt.
 *
 * @param {string} purchaseToken - The token associated with the purchase.
 * @param {Object} cardValues - The card values and billing address information.
 * @param {Object} paymentInfo - Additional payment information.
 * @returns {Function} A thunk action that dispatches actions based on the credit card payment process.
 */
export function cardPaymentAttempt(purchaseToken, cardValues, paymentInfo) {
  return (dispatch, getState) => {
    const { purchase: purchaseState } = getState();
    dispatch(updatePurchase(true));
    dispatch(requestPayment());

    // this is the new way to get the payment engine for each payment method
    const selectedPaymentMethod = purchaseState.get('selectedPaymentMethod');
    const extra = selectedPaymentMethod?.extra ?? {};
    // defaults to purchase payment engine for backwards compatibility
    const paymentEngine = selectedPaymentMethod?.engine ?? purchaseState.get('paymentEngine');
    const cardPayment = cardFactory.create({ engine: paymentEngine });

    return cardPayment
      .createPayload(cardValues, { ...extra, ...paymentInfo })
      .then((enginePayload) => {
        const paymentPayload = {
          ...getPaymentPayload('credit_card', paymentEngine, cardValues),
          ...enginePayload,
        };
        return purchase
          .createPayment(purchaseToken, paymentPayload, {
            onReceivePayment: (response) => {
              paymentPollingHandler(dispatch, purchaseToken, response);
            },
          })
          .catch((error) => {
            paymentFailureHandler(dispatch, error);
          });
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

/**
 * Initiates a store payment attempt.
 *
 * @param {Object} paymentData - The payment data required for store payment.
 * @param {string} paymentData.token - The token representing the payment session.
 * @param {Object} paymentData.fields - Additional fields required for the payment.
 * @returns {Function} A thunk action that dispatches actions based on the store payment process.
 */
export function storePaymentAttempt({ token, ...fields }) {
  return (dispatch, getState) => {
    dispatch(updatePurchase(true));
    dispatch(requestPayment());

    // this is the new way to get the payment engine for each payment method
    const selectedPaymentMethod = getState().purchase.get('selectedPaymentMethod');
    // defaults to kushki for backwards compatibility
    const paymentEngine = selectedPaymentMethod?.engine ?? 'kushki';
    const storePayment = storeFactory.create({ engine: paymentEngine });

    return storePayment
      .getPayload(fields)
      .then((enginePayload) => {
        const payload = {
          ...getPaymentPayload('store', paymentEngine),
          ...enginePayload,
        };

        return purchase
          .createPayment(token, payload, {
            onReceivePayment: (response) => paymentPollingHandler(dispatch, token, response),
          })
          .catch((error) => paymentFailureHandler(dispatch, error, true));
      })
      .catch((error) => paymentFailureHandler(dispatch, error, true));
  };
}

/**
 * Initiates a payment attempt based on the payment type.
 *
 * @param {string} token - The token representing the payment session.
 * @param {string} type - The type of payment to attempt (e.g., 'paypal').
 * @returns {Function} A thunk action that dispatches actions based on the payment attempt process.
 */
export function paymentAttempt(token, type) {
  return (dispatch) => {
    dispatch(updatePurchase(true));
    dispatch(requestPayment());

    if (type === 'paypal') {
      return purchase
        .createPayment(token, getPaymentPayload(type, type), { init: false })
        .then(({ payload }) => dispatch(receivePayment(payload)))
        .catch((error) => paymentFailureHandler(dispatch, error));
    }

    return purchase
      .createPayment(token, getPaymentPayload(type), {
        onReceivePayment: (response) => paymentPollingHandler(dispatch, token, response),
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

/**
 * Initiates a third-party payment attempt.
 *
 * @param {string} token - The token representing the payment session.
 * @param {Object} paymentData - The payment data required for the third-party payment.
 * @param {string} paymentData.engine - The payment engine to be used.
 * @param {string} paymentData.type - The type of payment to attempt.
 * @returns {Function} A thunk action that dispatches actions based on the third-party payment attempt process.
 */
export function thirdPartyPaymentAttempt(token, { engine, type }) {
  return (dispatch) => {
    dispatch(updatePurchase(true));
    dispatch(requestPayment());

    return purchase
      .createPayment(token, getPaymentPayload(type, engine), { init: false })
      .then(({ payload }) => dispatch(receivePayment(payload)))
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

/**
 * Retrieves the payment details for a given payment ID and purchase token.
 *
 * @param {string} paymentId - The ID of the payment to retrieve.
 * @param {string} purchaseToken - The token associated with the purchase.
 * @returns {Function} A thunk action that dispatches actions to handle the payment retrieval process.
 */
export function getPayment(paymentId, purchaseToken) {
  return (dispatch) => {
    dispatch(requestPayment());
    // return a promise to wait for
    return Promise.all([purchase.get(purchaseToken), purchase.getPayment(purchaseToken, paymentId)])
      .then(([purchaseResponse, paymentResponse]) => {
        dispatch(receivePurchase(purchaseResponse));
        dispatch(receivePayment(paymentResponse));
      })
      .catch((error) => {
        dispatch(setError(304, 'error_when_looking_for_payment', 'error', true));
        dispatch(receivePayment());
        throw new Error(`Error 304: Failed to get payment. ${error.message}`);
      });
  };
}

/**
 * Polls the payment service for a transfer payment result within a specified time frame.
 *
 * @param {Object} config - The configuration object for the payment polling.
 * @param {string} config.purchaseToken - The token associated with the purchase.
 * @param {string} config.paymentId - The ID of the payment to poll for.
 * @param {number} [config.minutes=21] - The total duration in minutes to poll for the payment result.
 * @param {number} [config.interval=2] - The interval in seconds between each poll attempt.
 * @returns {Promise<Object>} A promise that resolves with the payment result or rejects with an error.
 */
export function transferPaymentResult({ purchaseToken, paymentId, minutes = 21, interval = 2 }) {
  return new Promise((resolve, reject) => {
    const pollOptions = {
      interval: interval * 1000,
      maxRetries: (minutes * 60) / interval,
    };

    /**
     * Callback function for handling the payment response.
     *
     * @callback onReceivePayment
     * @param {Object} poll - The response object from the payment request.
     */
    const onReceivePayment = (poll) => {
      if (!['pending', 'completed'].includes(poll.status)) {
        return reject(camelizeKeys(poll.payload));
      }

      const status = poll.payload.kushki_processor_state;
      const purchaseState = poll.payload.purchase_state;
      const paymentEngine = poll.payload.payment_engine;
      switch (status) {
        case 'OK':
          if (purchaseState !== 'attempt') {
            resolve(camelizeKeys(poll.payload));
          }
          break;
        case 'PENDING':
          break;
        default:
          /**
           * When the user cancels the payment (using Mercado Pago) by going back from the PSE page to the checkout
           * the Purchase API doesn't set the kushki_processor_state property,
           * this causes the poll to fail and the promise to be rejected.
           *
           * This is a workaround to avoid the rejection of the promise until the poll.status is "aborted"
           */
          if (paymentEngine === availablePaymentEngines.mercadoPago && poll.status === 'pending') {
            break;
          }
          reject(camelizeKeys(poll.payload));
          break;
      }
    };

    purchase.getPayment(purchaseToken, paymentId, {
      onReceivePayment,
      options: pollOptions,
    });
  });
}

/**
 * Initiates the polling of transfer payment status and dispatches actions accordingly.
 *
 * @param {Object} paymentConfig - The configuration object for the transfer payment polling.
 * @returns {Function} A thunk action that dispatches actions based on the transfer payment polling process.
 */
export function pollTransferPayment(paymentConfig) {
  return (dispatch) => {
    transferPaymentResult(paymentConfig)
      .then((response) => {
        dispatch(receivePayment(response));
      })
      .catch((response) => {
        const error = response.kushkiProcessorState;

        if (error === 'NOT_AUTHORIZED') {
          dispatch(setError(300, 'transaction_declined', 'warning', false));
        } else if (error === 'FAILED') {
          dispatch(setError(301, 'transaction_failed', 'warning', false));
        } else {
          dispatch(setError(301, 'error_generating_payment', 'warning', false));
        }

        /**
         * For some reason, the payment data can be returned either in the response.payload or directly in the response.
         * This was causing a bug that prevented the user from being redirected back to the checkout when the payment failed.
         */
        dispatch(receivePayment(response.payload ? response.payload : response));
        dispatch(updatePurchase(false));
      });
  };
}

/**
 * Confirms the PayPal payment.
 *
 * @param {string} purchaseToken - The token associated with the purchase.
 * @param {string} paymentId - The ID of the PayPal payment to confirm.
 * @returns {Function} A thunk action that dispatches actions based on the PayPal payment confirmation process.
 */
export function confirmPaypalPayment(purchaseToken, paymentId) {
  return (dispatch) => {
    dispatch(requestPayment());

    return purchase
      .confirmPaypalPayment(purchaseToken, paymentId, {
        onReceivePayment: (response) => {
          if (response.payload.status === 'failed') {
            // Manually navigate to the failed payment page
            window.location = `/payment/${purchaseToken}/${paymentId}/failure`;
          } else {
            paymentPollingHandler(dispatch, purchaseToken, response);
          }
        },
      })
      .catch((error) => paymentFailureHandler(dispatch, error));
  };
}

/**
 * Initiates a transfer payment attempt.
 *
 * @param {Object} config - The configuration object for the transfer payment.
 * @param {string} config.engine - The payment engine to be used.
 * @param {string} config.provider - The payment provider.
 * @param {string} config.purchaseToken - The token associated with the purchase.
 * @param {string} config.bankId - The ID of the bank for the transfer.
 * @returns {Function} A thunk action that dispatches actions based on the transfer payment attempt process.
 */
export function transferPaymentAttempt(config) {
  return async (dispatch, getState) => {
    try {
      const { engine, provider, purchaseToken, bankId } = config;
      const transferFactoryPayload = engine
        ? { engine, provider }
        : { engine: 'kushki', provider: 'pse' };
      const transfer = transferFactory.create(transferFactoryPayload);

      dispatch(updatePurchase(true));
      dispatch(requestPayment());

      const envConfig = getState().whitelabelConfig.env;
      const token = await transfer.createToken({
        ...config,
        currency: envConfig.currency,
        callbackUrl: `${envConfig.api.purchaseUrl}/v2/kushki/transfer_in_redirect`,
      });

      /**
       * @todo Add a method called getEntityTypes(personType) to the Transfer template
       * and remove this conditional when the method is implemented
       */
      const mercadoPagoEntityTypes = ['individual', 'association'];
      const isMercadoPago = engine === availablePaymentEngines.mercadoPago;
      const entityType = isMercadoPago ? mercadoPagoEntityTypes[config.personType] : '';
      const paymentTypesByEngines = {
        nequi: 'app_notify',
        default: 'transfer',
      };

      const createTransferPayload = {
        payment_type: paymentTypesByEngines[engine] || paymentTypesByEngines.default,
        payment_engine: engine || 'kushki',
        payment_provider: provider,
        tracker_id: getDistinctId(),
        device_session_id: getSessionId(),
        monthly_selected_plan: 1,
        transfer_token: token,
        bank_id: bankId,
        entity_type: entityType,
      };

      if (engine === 'nequi') {
        return purchase
          .createPayment(purchaseToken, createTransferPayload, {
            onReceivePayment: (response) => {
              /**
               * Nequi has payment_type = 'app_notify', so we need to change it to 'transfer'
               */
              response.payload.payment_type = 'transfer';
              if (response.payload.human_error_reason) {
                paymentFailureHandler(dispatch, {
                  humanErrorReason: response.payload.human_error_reason,
                });
              } else {
                paymentPollingHandler(dispatch, purchaseToken, response);
              }
            },
          })
          .catch((error) => paymentFailureHandler(dispatch, error));
      }

      const response = await purchase.createPayment(purchaseToken, createTransferPayload, {
        init: false,
      });

      if (transfer.needsRedirect()) {
        window.location = response.payload.redirect_to;
      } else {
        paymentPollingHandler(dispatch, purchaseToken, response);
      }
    } catch (error) {
      paymentFailureHandler(dispatch, error);
    }
  };
}

export function evertecPaymentAttempt({ purchaseToken, type, provider, requestId }) {
  return (dispatch) => {
    try {
      const paymentPayload = {
        payment_engine: 'evertec',
        payment_type: type,
        payment_provider: provider,
        tracker_id: getDistinctId(),
        device_session_id: getSessionId(),
        reference_id: requestId,
      };
      return purchase
        .createPayment(purchaseToken, paymentPayload, { init: false })
        .then(({ payload }) => dispatch(receivePayment(payload)))
        .catch((error) => {
          paymentFailureHandler(dispatch, error);
          throw new Error(`Error on evertecPaymentAttempt: ${error}`);
        });
    } catch (error) {
      paymentFailureHandler(dispatch, error);
      throw new Error(`Error on paymentPayload for evertecPaymentAttempt: ${error}`);
    }
  };
}

export function confirmEvertecPayment() {
  return (dispatch, getState) => {
    dispatch(requestPayment());
    try {
      const { purchase: purchaseState, payment: paymentState } = getState();
      const token = purchaseState.get('token');
      const paymentId = paymentState.get('id');
      return purchase.getPayment(token, paymentId).then((response) => {
        dispatch(receivePayment(response));
        dispatch(getPurchase(token));
      });
    } catch (error) {
      paymentFailureHandler(dispatch, error);
    }
  };
}

/**
 * Initiates a payment attempt using MercadoPago.
 *
 * @param {Object} mercadoPagoFormData - The form data required for MercadoPago payment.
 * @param {string} mercadoPagoFormData.token - The token representing the card data.
 * @param {number} mercadoPagoFormData.installments - The number of installments for the payment.
 * @param {string} mercadoPagoFormData.mpDeviceSessionId - The device session ID for fraud prevention.
 * @param {string} mercadoPagoFormData.paymentMethodId - The ID of the chosen payment method.
 * @param {string} mercadoPagoFormData.issuerId - The ID of the card issuer.
 * @returns {Function} A thunk action that dispatches actions based on the MercadoPago payment process.
 */
export function mercadoPagoPaymentAttempt(mercadoPagoFormData) {
  const { token, installments, mpDeviceSessionId, paymentMethodId, issuerId } = mercadoPagoFormData;
  return (dispatch, getState) => {
    const { purchase: purchaseState } = getState();
    const purchaseToken = purchaseState.get('token');

    const cardValues = {
      cardBrand: paymentMethodId,
      monthlySelectedPlan: Number(installments),
    };

    const paymentInfo = {
      mpDeviceSessionId,
      mercadoPagoToken: token,
      issuerId,
    };

    return dispatch(cardPaymentAttempt(purchaseToken, cardValues, paymentInfo));
  };
}

/**
 * Initiates a payment attempt using Yuno.
 *
 * @param {Object} config - The configuration object for the Yuno payment attempt.
 * @param {string} config.checkoutSession - The checkout session ID for the Yuno payment.
 * @param {string} config.merchantOrderId - The merchant order ID associated with the payment.
 * @param {Object} config.selectedPaymentMethod - The selected payment method details.
 * @param {string} config.selectedPaymentMethod.type - The type of payment (e.g., 'credit_card').
 * @param {string} config.selectedPaymentMethod.provider - The payment provider (e.g., 'yuno').
 * @param {string} config.selectedPaymentMethod.engine - The payment engine to be used.
 * @param {string} config.purchaseToken - The token associated with the purchase.
 * @returns {Function} A thunk action that dispatches actions based on the Yuno payment process.
 */
export function yunoPaymentAttempt(config) {
  const { checkoutSession, merchantOrderId, selectedPaymentMethod, purchaseToken } = config;
  const { type, provider, engine } = selectedPaymentMethod;
  return (dispatch) => {
    const paymentPayload = {
      payment_type: type,
      payment_engine: provider,
      yuno_payment_method_type: engine.toUpperCase(),
      yuno_checkout_session: checkoutSession,
      order_id: merchantOrderId,
    };

    return purchase
      .createPayment(purchaseToken, paymentPayload, {
        init: false,
      })
      .then(({ payload }) => {
        window.location = payload.redirect_to;
      })
      .catch((error) => {
        paymentFailureHandler(dispatch, error);
        throw new Error(`Error on creating Yuno payment: ${error}`);
      });
  };
}
